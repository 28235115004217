import '@css/style.scss';

import axios from "axios";   
import { gsap } from "gsap";   
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

/* animate numbers */
function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

let animatedNumberElement = document.querySelector(".js-animated-number");
if (animatedNumberElement instanceof HTMLElement) {
    gsap.from(
        animatedNumberElement,
        {
            scrollTrigger: ".js-animated-number",
            textContent: 0,
            duration: 2,
            ease: "power1.in",
            snap: { textContent: 1 },
            stagger: {
                each: 1.0,
                onUpdate: function() {
                this.targets()[0].innerHTML = numberWithCommas(Math.ceil(this.targets()[0].textContent));
                },
            }
        }
    );
}

// const teamTrigger = ScrollTrigger.create({
//     trigger: ".team",
//     pin: true,
//     start: "top top",
//     end: "+=300%",
//   });

const timeline = gsap.timeline({ scrollTrigger: '.team__grid' });
document.querySelectorAll(".team__card").forEach((teamCard)=>{
    timeline.from(teamCard, {
        duration: 0.15,
        delay: 0.1,
        opacity: 0,
        ease: "power1.in",
    });
});

/* rotate logo */
// gsap.set('#green', {xPercent:-50});
// gsap.timeline({
//   scrollTrigger:{
//     trigger: "#wrap",
//     pin: true,
//     scrub:0.2,
//     start: 'top top',
//     end:'+=10000',
//   }
// })
// .to('#green', {
//   rotation:360*5,
//   duration:1, ease:'none',
// })

/* stiky header */
let header = document.getElementById('header');
if (header) {
    ['scroll', 'resize'].forEach(function(e){
        window.addEventListener(e, function() {
            header.classList.toggle('header--sticky', window.scrollY > 1);
        }, false);
    });

    // initial check
    window.dispatchEvent( new Event("resize") );
    console.log('founded')
} else {
    console.log('No header founded')
}

/* nav area */
function toggleNavigation(){
    let toggler = document.querySelector('.nav-toggler');
    let togglerArea = document.querySelector('.nav-area');
    if (toggler instanceof HTMLElement && togglerArea instanceof HTMLElement ) {
        toggler.classList.toggle('nav-toggler--toggled');
        togglerArea.classList.toggle('nav-area--toggled');
    }
}
let toggler = document.querySelector('.nav-toggler');
if (toggler instanceof HTMLElement) {
    toggler.addEventListener('click', function(event) {
        toggleNavigation();
    }, false);
}


/* spoiler */
let spoiler = document.querySelector('.js-spoiler');
if (spoiler) {
    let plus = spoiler.querySelector('.spoiler__plus');
    let content = spoiler.querySelector('.spoiler__content');
    plus.addEventListener('click', function(event) {
        plus.classList.toggle('spoiler__plus--toggled');
        content.classList.toggle('spoiler__content--toggled');
    }, false);
}

/* smooth navigation */
function scrollToElement( element = null, hash = '', offset = 0) {
    if (!(element instanceof HTMLElement) && hash.length > 2 && hash.indexOf('#') >= 0) {
      element = document.querySelector( hash );
    }
    if ( element && element instanceof HTMLElement ) {
      let y = element.getBoundingClientRect().top + window.scrollY - offset;
      window.scrollTo({top: y, behavior: 'smooth'});
    } else {
      console.log('Element: %o, Hash: %s', element, hash);
    }
}
document.querySelectorAll('a[href^="#"]').forEach(link => {
    link.addEventListener('click', function(event){
      event.preventDefault();
      scrollToElement(null, link.hash, 106);
    });
  });

/* rotate on scroll*/
let rotated = document.querySelector('.js-rotated-element');
if (rotated instanceof HTMLElement) {
    window.addEventListener('scroll', ()=> {
        (function recurse() {
            const lastScrollPosition = window.scrollY;
            rotated.style.setProperty("--rotation-deg", lastScrollPosition);
            requestAnimationFrame(recurse);
        })();
    });
}

document.querySelectorAll('form').forEach( (form) => {
    form.addEventListener('submit', (event) => {
        event.preventDefault();

        const formdata = new FormData( form );

        axios({
            method: "post",
            url: "/crm.php",
            data: formdata,
            headers: { "Content-Type": "multipart/form-data" },
          })
            .then(function (response) {
                if (a)
              console.log(response.data.response);
              console.log(response.data.message);
              Promise.reject(response);
            })
            .catch(function (response) {
                if ( response.data ){
                    alert(response.data.message) 
                }
            });
    })
});
// transform: rotate( calc(var(--transform) / 6 * 1deg));
// transition: transform 1.25s ease-out;

/* videos lazy loading */
// var lazyVideos = [].slice.call(document.querySelectorAll("video.lazy"));
// if (lazyVideos && "IntersectionObserver" in window) {
//     var lazyVideoObserver = new IntersectionObserver(function(entries, observer) {
//     entries.forEach(function(video) {
//         if (video.isIntersecting) {
//         for (var source in video.target.children) {
//             var videoSource = video.target.children[source];
//             if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
//             videoSource.src = videoSource.dataset.src;
//             if (video && video.playbackRate) {
//                 video.playbackRate = 0.5;
//             }
//             }
//         }

//         video.target.load();
//         video.target.classList.remove("lazy");
//         lazyVideoObserver.unobserve(video.target);
//         }
//     });
//     });

//     lazyVideos.forEach(function(lazyVideo) {
//     lazyVideoObserver.observe(lazyVideo);
//     });
// }


// let emailInput = document.querySelector('[type="email"]');
// if (emailInput && window['IMask']) {
//   let maskOptions = {
//     mask: '+{38}(000)000-00-00'
//   };
//   let mask = IMask(emailInput, maskOptions);
// }